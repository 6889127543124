import createFocusTrap from 'focus-trap';
import constants from '../../scripts/constants';

const selectors = {
  filters: '.cta-search-filters',
  clearFilters: '#clear-filters',
  closeFilter: '.cta-search-filters__headline-close',
  filterLists: '.filter__list',
  filtersContent: '.cta-search-filters__content',
  filterTitles: '.filter__title',
  openFilter: '.cta-search-filters__headline-open',
  refineResults: '#refine-results',
  searchInput: '.search_search-bar',
};

/**
 * @param {object} event - event triggered.
 * */
function filterTitleListener(event) {
  if (event.currentTarget.getAttribute('aria-expanded') === 'true') {
    const node = document.createElement('div');
    event.currentTarget.setAttribute('aria-expanded', 'false');
    event.currentTarget.children[0].removeChild(event.currentTarget.children[0].children[0]);
    node.innerHTML = constants.caretDown;
    event.currentTarget.children[0].appendChild(node);
    event.currentTarget.nextElementSibling.setAttribute('aria-expanded', 'false');
  } else {
    const node = document.createElement('div');
    event.currentTarget.setAttribute('aria-expanded', 'true');
    event.currentTarget.children[0].removeChild(event.currentTarget.children[0].children[0]);
    node.innerHTML = constants.caretUp;
    event.currentTarget.children[0].appendChild(node);
    event.currentTarget.nextElementSibling.setAttribute('aria-expanded', 'true');
  }
}

/**
 * @param {object} item - filters group title.
 * */
function verifyFilters(item) {
  const node = document.createElement('div');
  node.innerHTML = constants.caretUp;
  item.children[0].appendChild(node);
  item.nextElementSibling.setAttribute('aria-expanded', 'true');
}

function adjustFilter() {
  const filters = document.querySelector(selectors.filters);
  const filtersContent = document.querySelector(selectors.filtersContent);
  const { innerWidth } = window;
  if (innerWidth >= 992) {
    if (filters.getAttribute('aria-expanded') === 'true') {
      document.body.style.overflowY = 'auto';
    }
    filtersContent.style.display = 'block';
    filters.style.height = '100%';
    filters.style.transition = 'none';
  } else if (filters.getAttribute('aria-expanded') === 'true') {
    document.body.style.overflowY = 'hidden';
    filters.style.height = '100%';
    filters.style.overflowY = 'scroll';
  } else {
    filtersContent.style.display = 'none';
    filters.style.height = '9rem';
    filters.style.overflowY = 'auto';
  }
}

function updateQueryParam(params, searchQueryIdentifier, term) {
  const key = encodeURI(searchQueryIdentifier);

  if (term == "" && params.get(key) == null) {
    return params;
  }

  const value = encodeURI(term);

  if (params == null) {
    params = "?";
  }

  if (params.get(key) == null) {
    params.append(key, value);
  } else if (term == "") {
    params.delete(key);
  } else {
    params.set(key, value);
  }

  return params;
}

class SearchFilters {
  /**
   * Constructor
   * @param {object} filtersContainer - search filters DOM element
   */
  constructor(filtersContainer) {
    if (!filtersContainer) {
      // eslint-disable-next-line no-console
      console.warn('No search filters element found ');
      return;
    }

    window.addEventListener('resize', adjustFilter);
    window.addEventListener('orientationchange', adjustFilter);

    this.filters = filtersContainer;
    this.selectedFilters = {};
    this.clearFilters = document.querySelector(selectors.clearFilters);
    this.closeFilter = document.querySelector(selectors.closeFilter);
    this.filterLists = document.querySelectorAll(selectors.filterLists);
    this.filtersContent = document.querySelector(selectors.filtersContent);
    this.filterTitles = document.querySelectorAll(selectors.filterTitles);
    this.openFilter = document.querySelector(selectors.openFilter);
    this.refineResults = document.querySelector(selectors.refineResults);
    this.searchInput = document.querySelector(selectors.searchInput);

    this.trap = createFocusTrap(this.filters);

    this.openFilter.addEventListener('click', this.onClickOpenFilter.bind(this));

    this.closeFilter.addEventListener('click', this.onClickCloseFilter.bind(this));

    this.refineResults.addEventListener('click', this.onClickRefineResults.bind(this));

    this.clearFilters.addEventListener('click', this.onClickClearFilters.bind(this));

    Array.prototype.slice.call(this.filterTitles).forEach(filterTitle => {
      verifyFilters(filterTitle);
      filterTitle.addEventListener('click', event => filterTitleListener(event));
    });

    this.refineResults.setAttribute('disabled', 'true');
    this.clearFilters.setAttribute('disabled', 'true');

    /**
     * This loops against the lists of filters and calls verifySelect for each filter
     * It also adds the click event listener for each filter.
     * If there are not selected items refine results and clear filters buttons will be disabled.
     */
    Array.prototype.slice.call(this.filterLists).forEach(filterList => {
      const filterItems = Array.prototype.slice.call(filterList.children);
      const selectedListItem = filterItems.filter(item => item.getAttribute('aria-selected'))[0];
      if (selectedListItem != null) {
        this.refineResults.removeAttribute('disabled');
        this.clearFilters.removeAttribute('disabled');
      }
      filterItems.forEach(filterItem => {
        this.verifySelect(filterItem, filterList, selectedListItem);
        filterItem.addEventListener('click', event => {
          this.filterItemListener(
            filterList,
            event.currentTarget.getAttribute('data-filter'),
            event.currentTarget.getAttribute('aria-selected'),
            event.currentTarget.getAttribute('aria-disabled')
            );
          this.refineResults.removeAttribute('disabled');
          this.clearFilters.removeAttribute('disabled');
        });
      });
    });
  }

  onClickOpenFilter() {
    document.body.style.overflowY = 'hidden';
    this.closeFilter.style.display = 'block';
    this.filters.setAttribute('aria-expanded', 'true');
    this.filters.style.height = '100%';
    this.filters.style.overflowY = 'scroll';
    this.filters.style.transition = 'height 0.3s ease-in-out';
    this.filtersContent.style.display = 'block';
    this.openFilter.style.display = 'none';
    this.trap.activate();
    this.filters.classList.add('is-trap-active');
    // eslint-disable-next-line func-names
    document.onkeydown = function(event) {
      const { innerWidth } = window;
      if (
        innerWidth < 992 &&
        this.filters.classList.contains('is-trap-active') &&
        event.keyCode === 27
      ) {
        this.onClickCloseFilter();
      }
    }.bind(this);
  }

  onClickCloseFilter() {
    document.body.style.overflowY = 'auto';
    this.closeFilter.style.display = 'none';
    this.filters.setAttribute('aria-expanded', 'false');
    this.filters.style.height = '9rem';
    this.filters.style.overflowY = 'auto';
    this.filters.style.transition = 'height 0.3s ease-in-out';
    this.filtersContent.style.display = 'none';
    this.openFilter.style.display = 'block';
    this.trap.deactivate();
    this.filters.classList.remove('is-trap-active');
    document.onkeydown = null;
  }

  onClickRefineResults() {
    let params = new URLSearchParams(window.location.search);
    if (this.searchInput.value) {
      params = updateQueryParam(params, "search", this.searchInput.value);
    } else {
      params = updateQueryParam(params, "search", "");
    }

    if (this.selectedFilters.type) {
      params = updateQueryParam(params, "type", this.selectedFilters.type);
    } else {
      params = updateQueryParam(params, "type", "");
    }

    if (this.selectedFilters.topic) {
      params = updateQueryParam(params, "topic", this.selectedFilters.topic);
    } else {
      params = updateQueryParam(params, "topic", "");
    }

    window.location.search = params;
  }

  onClickClearFilters() {
    Array.prototype.slice.call(this.filterLists).forEach(filterList => {
      const filterItems = Array.prototype.slice.call(filterList.children);
      filterItems.forEach(item => {
        if (item.getAttribute('aria-selected') === 'true') {
          item.setAttribute('aria-selected', 'false');
          item.children[0].removeChild(item.children[0].children[0]);
        } else {
          item.removeAttribute('aria-disabled');
          item.children[0].removeAttribute('disabled');
        }
      });
    });
    this.selectedFilters = {
      type: null,
      topic: null,
    };
    window.location.search = '';
  }

  /**
   * @param {object} parent - Parent object of the item clicked.
   * @param {object} ariaSelected - aria attribute for selected items.
   * @param {string} dataFilter - clicked item identifier.
   * */
  filterItemListener(parent, dataFilter, ariaSelected, ariaDisabled) {
    const selectedChild = Array.prototype.slice
      .call(parent.children)
      .filter(child => child.getAttribute('data-filter') === dataFilter)[0];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < parent.children.length; i++) {
      const child = parent.children[i];
      if (ariaSelected === 'true') {
        if (child.getAttribute('data-filter') !== dataFilter) {
          child.setAttribute('aria-disabled', 'false');
          child.children[0].removeAttribute('disabled');
        }
      } else if (ariaDisabled !== 'true') {
        if (child.getAttribute('data-filter') !== dataFilter) {
          child.setAttribute('aria-disabled', 'true');
          child.children[0].setAttribute('disabled', 'true');
        }
      }
    }

    if (ariaSelected === 'true') {
      selectedChild.setAttribute('aria-selected', 'false');
      selectedChild.children[0].removeChild(selectedChild.children[0].children[0]);

      if (parent.getAttribute('data-filter-group') === 'Type') {
        this.selectedFilters.type = null;
      } else {
        this.selectedFilters.topic = null;
      }
    } else if (ariaDisabled !== 'true') {
      const node = document.createElement('div');
      selectedChild.setAttribute('aria-selected', 'true');
      node.innerHTML = constants.close;
      selectedChild.children[0].appendChild(node);

      if (parent.getAttribute('data-filter-group') === 'Type') {
        this.selectedFilters.type = selectedChild.getAttribute('data-filter');
      } else {
        this.selectedFilters.topic = selectedChild.getAttribute('data-filter');
      }
    }
    if (!this.selectedFilters.type && !this.selectedFilters.topic) {
      this.refineResults.setAttribute('disabled', 'true');
      this.clearFilters.setAttribute('disabled', 'true');
    } else {
      this.refineResults.removeAttribute('disabled');
      this.clearFilters.removeAttribute('disabled');
    }
  }

  /** verifySelect checks if the current item has the aria-selected attr in the html
   * or compares against the selected one in the current list. If the item is not selected
   * it adds aria-selected false and disabled the inner button.
   * @param {object} item - filter item.
   * @param {object} parent - Parent object of the item clicked.
   * @param {object} selectedItem - Selected item in the current list.
   * */
  verifySelect(item, parent, selectedItem) {
    if (
      item.getAttribute('aria-selected') === 'true' ||
      item.getAttribute('data-filter') ===
        this.selectedFilters[parent.getAttribute('data-filter-group').toLowerCase()]
    ) {
      const node = document.createElement('div');
      node.innerHTML = constants.close;
      item.setAttribute('aria-selected', 'true');
      item.children[0].appendChild(node);
      if (parent.getAttribute('data-filter-group') === 'Type') {
        this.selectedFilters.type = item.getAttribute('data-filter');
      } else {
        this.selectedFilters.topic = item.getAttribute('data-filter');
      }
    } else if (selectedItem && !item.getAttribute('aria-selected')) {
      item.setAttribute('aria-selected', 'false');
      item.setAttribute('aria-disabled', 'true');
      item.children[0].setAttribute('disabled', 'true');
    }
  }
}

export default SearchFilters;
