/**
 * NavVertical
 * Controls the flyout interactions,
 */
class Flyout {
  /**
   * Sets class props
   * @param {node} element nav
   * @param {node} element flyt out parent container
   * @param {function} callback function in parent, use to notify parent of any change
   */
  constructor({ element, callback }) {
    this.callback = callback;
    this.element = element;
    this.close = this.element.querySelector('.nav-flyout__close');

    if (this.close) {
      this.close.addEventListener('click', this.reset.bind(this));
    }
  }

  reset() {
    this.element.setAttribute('data-is-open', false);
    this.element.parentElement.setAttribute('data-fo-selected', false);

    this.callback(this.element);
  }
}

export default Flyout;
