import createFocusTrap from 'focus-trap';
import { Breakpoints } from '../../scripts/constants';

import Flyout from './navigation-flyout';

const selectors = {
  items: '.cta-nav__items',
  item: '.cta-nav__item',
  mainCTA: '[data-cta="main"]',
};

const dataAttr = {
  ariaExpanded: 'aria-expanded',
  flyoutSelected: 'data-fo-selected',
  isOpen: 'data-is-open',
};

/**
 * NavVertical
 * Controls the nav header interactions,
 */
class NavVertical {
  /**
   * Sets class props
   * @param {node} element nav
   * @param {node} toggleCTA element that will toggle the visibility of the component
   * @param {function} onOpenState callback to trigger when open state changes
   * @param {node} focusOn element on which the `focus-trap` will be applied
   */
  constructor({ element, toggleCTA, onOpenState, focusOn }) {
    if(!element) return;

    this.element = element;
    this.toggleCTA = toggleCTA;
    this.onOpenState = onOpenState;
    this.items = this.element.querySelectorAll(selectors.item);
    this.itemsContent = this.element.querySelector(selectors.items);
    this.mainCTAs = this.itemsContent.querySelectorAll(selectors.mainCTA);

    this.state = {
      open: false,
    };

    // Set inital state
    this.setPageHeaderState(this.state);

    // Bind action to toggle the header's visibility
    if (this.toggleCTA) {
      this.toggleCTA.addEventListener('click', this.toggleMenu.bind(this));
    }

    this.focusTrap = createFocusTrap(focusOn || this.element, {
      initialFocus: this.mainCTAs[0],
      onDeactivate: () => {
        if (this.state.open) {
          this.toggleMenu();
        }

        // Reset flyout
        if (this.itemsContent.dataset.foSelected) {
          this.setIsOpenOnItems();
          this.toggleAriaExpanded();

          this.itemsContent.setAttribute(dataAttr.flyoutSelected, false);
        }
      },
    });

    // Workaround for removing the listener within itself when binding `this`
    this.closeMenuListenerInstance = this.closeMenuListener.bind(this);

    // Store current window size, will be used to close de menu on resize
    this.windowInnerWidth = window.innerWidth;

    this.onBackBtnInstance = this.onBackBtn.bind(this);
    Array.prototype.slice
      .call(this.items)
      .forEach(item => new Flyout({ element: item, callback: this.onBackBtnInstance }));

    this.itemsContent.addEventListener('click', this.flyoutSelected.bind(this));
  }

  /**
   * Focus on CTA associated to flytout that have been closed
   * @param {node} element items container
   */
  onBackBtn(element) {
    this.setIsOpenOnItems();
    this.toggleAriaExpanded();
    element.querySelector(selectors.mainCTA).focus();
  }

  /**
   * Update items `data-is-open` property on items
   * @param {bool} isOpen
   */
  setIsOpenOnItems(isOpen = false) {
    Array.prototype.slice
      .call(this.items)
      .forEach(item => item.setAttribute(dataAttr.isOpen, isOpen));
  }

  /**
   * Update items `aria-expanded` property on main CTAs
   * @param {bool} isExpanded
   */
  toggleAriaExpanded(isExpanded = false) {
    Array.prototype.slice
      .call(this.mainCTAs)
      .forEach(cta => cta.setAttribute(dataAttr.ariaExpanded, isExpanded));
  }

  /**
   * Updates component elements in relation to the selected option
   * @param {event} click
   * @param {event.srcElement} srcElement call to action
   */
  flyoutSelected({ srcElement }) {
    if (srcElement.dataset.cta === 'main') {
      this.setIsOpenOnItems();
      this.toggleAriaExpanded();

      srcElement.setAttribute(dataAttr.ariaExpanded, true);
      srcElement.parentElement.setAttribute(dataAttr.isOpen, true);
      srcElement.parentElement.parentElement.setAttribute(dataAttr.flyoutSelected, true);
      srcElement.parentElement.querySelector('.nav-flyout__cta').focus();
    }
  }

  /**
   * Callback on resize event.
   * Calls toggleMenu() If resize passes the threshold between medium and
   * large viewports
   */
  closeMenuListener({ srcElement }) {
    if (this.windowInnerWidth < Breakpoints.LG && srcElement.innerWidth > Breakpoints.LG) {
      // Resize from medium to large viewport
      this.toggleMenu();
    } else if (this.windowInnerWidth > Breakpoints.LG && srcElement.innerWidth < Breakpoints.LG) {
      // Resize from large to medium viewport
      this.toggleMenu();
    }
  }

  /**
   * Interface between focus trap and state management
   */
  toggleMenu() {
    this.toggleOpenState();
    this.setPageHeaderState(this.state);

    if (this.state.open) {
      // Update to current value
      this.windowInnerWidth = window.innerWidth;
      this.focusTrap.activate();
      window.addEventListener('resize', this.closeMenuListenerInstance);
    } else {
      this.focusTrap.deactivate();
      window.removeEventListener('resize', this.closeMenuListenerInstance);
    }
  }

  /**
   * updates DOM to hide nav
   */
  hideNav() {
    this.element.classList.add('d-none');
  }

  /**
   * updates DOM to show nav
   */
  showNav() {
    this.element.classList.remove('d-none');
  }

  /**
   * Updates DOM props associated to a11y
   * @param {object} class state
   */
  setPageHeaderState({ open }) {
    // Notify outside
    this.onOpenState(this.state.open);

    if (this.toggleCTA) {
      this.toggleCTA.setAttribute(dataAttr.ariaExpanded, open);
    }
  }

  /**
   * Update the DOM to display or hide elements in the Page Header element
   */
  toggleOpenState(forceOpen) {
    const newState = Object.assign({}, this.state);
    newState.open = forceOpen || !this.state.open;
    this.state = newState;
  }
}

export default NavVertical;
