import createFocusTrap from 'focus-trap';

class Dialog {
  /**
   *Creates an instance of Dialog.
   * @param {*} element
   * @memberof Dialog
   */
  constructor(element, config = {}) {
    // eslint-disable-next-line no-unused-vars
    const { onClose = null, onOpen = null, focusOn = null } = config;
    if (!element) {
      // eslint-disable-next-line no-console
      console.log('Class `Dialog` was instantiated but no DOM hero was provided');
      return;
    }

    this.element = element;

    if (config.onClose) {
      this.onClose = config.onClose;
    }

    if (config.onOpen) {
      this.onOpen = config.onOpen;
    }

    if (config.focusOn) {
      this.focusOn = config.focusOn;
    }

    this.focusTrap = createFocusTrap(this.focusOn || this.element, {
      onDeactivate: () => {
        this.element.setAttribute('data-dialog-visible', 'false');
        Array.prototype.slice.call(this.openTriggers).forEach(openTrigger => {
          openTrigger.setAttribute('aria-expanded', 'false');
        });
      },
      initialFocus: this.priorityFocus(),
    });

    const attr = this.element.getAttribute('data-dialog-content');
    this.openTriggers = document.querySelectorAll(`[data-dialog-open ="${attr}"]`);
    this.closeTriggers = document.querySelectorAll(`[data-dialog-close ="${attr}"]`);
    this.toggleTriggers = document.querySelectorAll(`[data-dialog-toggle ="${attr}"]`);

    Array.prototype.slice.call(this.openTriggers).forEach(openTrigger => {
      openTrigger.addEventListener('click', this.onClickOpenDialog.bind(this));
    });

    Array.prototype.slice.call(this.closeTriggers).forEach(closeTrigger => {
      closeTrigger.addEventListener('click', this.onClickCloseDialog.bind(this));
    });

    Array.prototype.slice.call(this.toggleTriggers).forEach(toggleTrigger => {
      toggleTrigger.addEventListener('click', this.onCLickToggleDialog.bind(this));
    });
  }

  onClickOpenDialog() {
    if (this.onOpen) {
      this.onOpen();
    }
    this.element.setAttribute('data-dialog-visible', 'true');
    if (this.dialogVisible()) {
      this.focusTrap.activate();
      Array.prototype.slice.call(this.openTriggers).forEach(openTrigger => {
        openTrigger.setAttribute('aria-expanded', 'true');
      });
    }
  }

  onClickCloseDialog() {
    this.focusTrap.deactivate();
    if (this.onClose) {
      this.onClose();
    }
  }

  onCLickToggleDialog() {
    if (this.dialogVisible()) {
      this.onClickCloseDialog();
    } else {
      this.onClickOpenDialog();
    }
  }

  dialogVisible() {
    return this.element.getAttribute('data-dialog-visible') === 'true';
  }

  priorityFocus() {
    return this.element.querySelector('[dialog-priority-focus="true"]');
  }
}

export default Dialog;
