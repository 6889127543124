const selectors = {
  ctaVideo: '.cta-hero-home__video',
  dataVideoControls: '.cta-hero-home__video-controls',
  dataVideoControl: '[data-video-control]',
  dataHeadline: '[data-headline]',
};

const modifiers = {
  animate: '--animate',
};

/**
 * Maps a string(s) to a string wrapping the initial string(s) in a div and span without spacing.
 * @param {string} string can be a single words or sentence, e.g: `hello world`
 * @return {string} map transformation, e.g: `<div><span>hello</span></div><div><span>worlds</span></div>`
 */
function mapTextToSpans(string) {
  return string
    .split(' ')
    .map(item => {
      return `<div><span>${item}</span></div>`;
    })
    .join(' ');
}

class HomeHero {
  /**
   * Constructs Class
   * @param {Object} element DOM Hero
   */
  constructor(element) {
    if (!element) {
      // eslint-disable-next-line no-console
      console.warn('Class `HomeHero` was instantiated but no DOM hero was provided');
      return;
    }

    this.element = element;
    this.headline = this.element.querySelector(selectors.dataHeadline);
    this.video = this.element.querySelector(selectors.ctaVideo);
    if (this.video) {
      this.videoControls = this.element.querySelector(selectors.dataVideoControls);
      this.videoControl = this.videoControls.querySelectorAll(selectors.dataVideoControl);
      this.videoControlPause = this.videoControls.querySelector('[data-video-control="pause"]');
      this.videoControlPlay = this.videoControls.querySelector('[data-video-control="play"]');

      Array.prototype.slice.call(this.videoControl).forEach(item => {
        item.addEventListener('click', this.togglePlayVideo.bind(this), false);
      });
    }

    this.setScene();
  }

  /**
   * Resume and stops the hero video
   */
  togglePlayVideo() {
    if (this.video.paused) {
      this.videoControls.setAttribute('data-video-controls-state', 'playing');
      this.video.play();
      this.videoControlPause.focus();
    } else {
      this.videoControls.setAttribute('data-video-controls-state', 'paused');
      this.video.pause();
      this.videoControlPlay.focus();
    }
  }

  /**
   * Removes class from parent container triggering animations
   */
  animate() {
    this.element.classList.remove(`cta-hero-home${modifiers.init}`);
  }

  /**
   * Applies DOM and CSS transformation to put DOM element in scene.
   */
  setScene() {
    this.headline.innerHTML = mapTextToSpans(this.headline.dataset.headline);

    // Wait for headline transformation to be applied
    requestAnimationFrame(() => {
      this.element.classList.remove(`preload`);
      this.element.classList.add(`cta-hero-home${modifiers.animate}`);
    });
  }
}

export default HomeHero;
