/* eslint-disable no-unused-vars */
import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';
import { polyfill } from 'es6-object-assign';

import Dialog from './dialog';
import HeroHome from '../components/hero-home/hero-home';
import NavVertical from '../components/nav/nav-vertical';
import NavHorizontal from '../components/nav/nav-horizontal';
import SearchFilters from '../components/search-filters/search-filters';
import SearchResults from '../components/search-results/search-results';

polyfill();

/**
 * Identifies if page is scrolled
 * @param {number} threshold reference point to were "scroll" should be considered
 * @return {bool} true if page is scrolled
 */
function isPageScrolled(threshold) {
  return window.pageYOffset > threshold;
}

document.getElementById("username").addEventListener("click", function () {
    var loginWindow = document.getElementById("login-window");
    if (loginWindow.classList.contains("hide"))
        loginWindow.classList.remove("hide");
    else
        loginWindow.classList.add("hide");
});

/**
 * Toggles data attribute indicating the document is vertically scrolled after
 * a delay
 * @param {node} element to asssign scrolled flag
 * @param {number} threshold reference point to were "scroll" should be considered
 * @return {funct} logic that updates the `data-is-scrolled` of the element
 */
function onDocumentScroll(element, threshold) {
  return () => {
    element.setAttribute('data-is-scrolled', isPageScrolled(threshold));
  };
}

/**
 * Updates the markup (at a page level) to reflect the state of the nav
 * @param {bool} state indicates if nav is visible
 */
function openNavOverlay(state) {
  const body = document.querySelector('body');
  const overlayBackdrop = document.querySelector('.cta-overlay[data-nav-overlay]');
  const pageHeader = document.querySelector('.cta-header');

  if (pageHeader && overlayBackdrop) {
    pageHeader.setAttribute('data-hamburger-expanded', state);
    overlayBackdrop.setAttribute('data-is-visible', state);
  }
}

const App = () => {
  const homeHero = document.querySelectorAll('.cta-hero-home');
  const searchFiltersElement = document.querySelector('.cta-search-filters');
  const searchResultsElement = document.querySelector('.cta-search-results');
  const navMenu = document.querySelector('.cta-nav[data-layout="vertical"]');
  const navBar = document.querySelector('.cta-nav[data-layout="horizontal"]');
  const body = document.querySelector('body');

  const ctaHeader = document.querySelector('.cta-header');
  const hamburger = ctaHeader ? ctaHeader.querySelector('.cta-header__hamburger') : undefined;
  const threshold = 73;
  body.setAttribute('data-is-scrolled', isPageScrolled(threshold));
  window.addEventListener('scroll', onDocumentScroll(body, threshold));

  Array.prototype.slice.call(homeHero).forEach(hero => new HeroHome(hero));
  const navVertical = new NavVertical({
    element: navMenu,
    toggleCTA: hamburger,
    onOpenState: openNavOverlay,
    focusOn: ctaHeader,
  });

  const navHorizontal = new NavHorizontal({
    element: navBar,
    onOpenState: openNavOverlay,
    focusOn: ctaHeader,
  });

  const searchFilters = new SearchFilters(searchFiltersElement);
  const searchResults = new SearchResults(searchResultsElement);

  const searchDialogElement = document.querySelector('[data-dialog-content="search"]');
  const searchDialog = new Dialog(searchDialogElement, {
    onClose: () => navVertical.showNav(),
    onOpen: () => navVertical.hideNav(),
    focusOn: ctaHeader,
  });
};

if (process.env.NODE_ENV === 'development') {
  window.addEventListener('load', App);
} else {
  document.addEventListener('DOMContentLoaded', App);
}

/**
 * Toggles search overlay nav icons
 */

const navSearchIcon = document.querySelectorAll('.nav-search');
function toggleHide() {
    navSearchIcon.forEach(function (elem) {
        elem.classList.toggle("hide");
    })
}

navSearchIcon.forEach(item => {
    item.addEventListener('click', function() {
        toggleHide();
    })
});