import constants from './constants';

class SearchService {
  constructor() {
    if (!SearchService.instance) {
      SearchService.instance = this;
    }
    this.searchApi = constants.searchApiUrl;
    return SearchService.instance;
  }

  loadMore(searchParam, type, topic, offset, count) {
    const nextPage = offset / count + 1;
    let query = `?page=${nextPage}&pagesize=${count}`;
    if(searchParam)
        query += `&search=${searchParam}`;
    if(type)
        query += `&type=${type}`;
    if(topic)
        query += `&topic=${topic}`;
    const searchUrl = `${this.searchApi}${query}`;
    return fetch(searchUrl).then(response => response.json());
  }
}

const instance = new SearchService();
Object.freeze(instance);
export default instance;
