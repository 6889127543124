import SearchService from '../../scripts/searchService';

const selectors = {
  loadMoreBtn: '#load-more',
  offset: '#offset',
  totalResults: '#total-results',
  results: '#results',
  resultsCounter: '#results-counter',
  resultsContainer: '.results-item-container',
};

/**
 * @param {string} name - query parameter name.
 */
function getParameterByName(name) {
  const match = RegExp(`[?&]${name}=([^&]*)`).exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

function animateResults() {
  const newResults = Array.prototype.slice.call(document.querySelectorAll('.is-loaded'));
  newResults.forEach(item => {
    item.classList.remove('is-loaded');
    item.classList.add('is-showing');
  });
  newResults[0].classList.add('is-focusable');
}

function focus() {
  const firstResult = document.querySelector('.is-focusable');
  const title = firstResult.querySelector('.cta-search-results__headline');
  title.focus();
  firstResult.classList.remove('is-focusable');
}

function responseToResultOffset(response) {
  return response.Total > 1 ? (response.Page - 1) * response.PageSize + response.Results.length : 1;
}

function responseToResultsTotal(response) {
  return `result${response.Total > 1 ? 's' : ''}`;
}

function responseToResultsCounter(response) {
  return `${response.Total} Result${response.Total > 1 ? 's' : ''}`;
}

class SearchResults {
  /**
   * Constructor
   * @param {object} searchResultsContainer - search results DOM element.
   */
  constructor(searchResultsContainer, perPage = 10) {
    if (!searchResultsContainer) {
      // eslint-disable-next-line no-console
      console.warn('No search results element found ');
      return;
    }
    this.perPage = perPage;
    this.searchResults = searchResultsContainer;
    this.loadMoreBtn = document.querySelector(selectors.loadMoreBtn);
    this.offset = document.querySelector(selectors.offset);
    this.totalResults = document.querySelector(selectors.totalResults);
    this.results = document.querySelector(selectors.results);
    this.resultsCounter = document.querySelector(selectors.resultsCounter);
    this.resultsContainer = document.querySelector(selectors.resultsContainer);
    this.offsetValue = this.offset.innerHTML;

    this.loadMoreBtn.addEventListener('click', this.onClickLoadMoreResults.bind(this));
    if(this.offsetValue == this.totalResults.innerHTML)
        this.loadMoreBtn.style.visibility = 'hidden';
  }

  onClickLoadMoreResults() {
    this.loadMoreBtn.setAttribute('disabled', 'true');
    this.loadMoreBtn.innerHTML = 'Loading...';
    const typeFilter = getParameterByName('type');
    const topicFilter = getParameterByName('topic');
    const search = getParameterByName('search');

    SearchService.loadMore(search, typeFilter, topicFilter, this.offsetValue, this.perPage).then(
      response => {
        // update offset data based on response.
        this.offset.innerHTML = responseToResultOffset(response);
        this.totalResults.innerHTML = response.Total;
        this.results.innerHTML = responseToResultsTotal(response);
        this.resultsCounter.innerHTML = responseToResultsCounter(response);
        this.offsetValue = (response.Page - 1) * response.PageSize + response.Results.length;

        fetch("/searchresults/results", {
            method: "POST", 
            body: JSON.stringify(response.Results),
            headers:{
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if(res.ok !== true) {
                console.log("Error retrieving results html.");
                return;
            }
            return res.text();
        }).then(text => {
            //Insert returned partial view into dom
            text = text.replace("cta-search-results__item", "cta-search-results__item is-loaded");
            this.resultsContainer.innerHTML += text;
            setTimeout(animateResults, 300);
            setTimeout(focus, 1000);
            // enable load more button again.
            this.loadMoreBtn.removeAttribute('disabled');
            this.loadMoreBtn.innerHTML = 'Load More';
            if (this.offsetValue == this.totalResults.innerHTML) {
                this.loadMoreBtn.style.visibility = 'hidden';
            }
        }).catch(function(error) {
          console.log('There has been a problem with your fetch operation: ', error.message);
        });
      }
    ).catch(function(error) {
        console.log('There has been a problem with your fetch operation: ', error.message);
    });;
  }
}

export default SearchResults;
