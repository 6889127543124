const searchApiUrl = window.SEARCH_API;

const close = `<svg width="11px" height="11px" viewBox="0 0 11 11" version="1.1" xmlns="http://www.w3.org/2000/svg">
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g fill="currentColor" fill-rule="nonzero">
      <polygon
        points="6.1406896 5.5 11 10.3593104 10.3593104 11 5.5 6.1406896 0.64068962 11 0 10.3593104 4.8593104 5.5 0 0.64068962 0.64068962 0 5.5 4.8593104 10.3593104 0 11 0.64068962">
      </polygon>
    </g>
  </g>
</svg>`;

const caretUp = `<svg width="10px" height="6px" viewBox="0 0 10 6" version="1.1" xmlns="http://www.w3.org/2000/svg">
<g stroke="none" stroke-width="1" fill="currentColor" fill-rule="nonzero">
  <g transform="translate(0.000000, -1.000000)" fill="currentColor" fill-rule="nonzero">
    <g
      transform="translate(5.000000, 3.661180) rotate(-180.000000) translate(-5.000000, -3.661180) translate(0.000000, 0.661180)">
      <polygon points="9.33851 0 10 0.66149 5.00031 5.66118 0 0.66149 0.66149 0 5.00031 4.3382">
      </polygon>
    </g>
  </g>
</g>
</svg>`;

const caretDown = `<svg width="10px" height="6px" viewBox="0 0 10 6" version="1.1" xmlns="http://www.w3.org/2000/svg">
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
  <g fill="currentColor" fill-rule="nonzero">
    <polygon points="9.33851 0 10 0.66149 5.00031 5.66118 0 0.66149 0.66149 0 5.00031 4.3382">
    </polygon>
  </g>
</g>
</svg>`;

const [XS, SM, MD, LG, XL] = [0, 480, 768, 992, 1280];
export const Breakpoints = { XS, SM, MD, LG, XL };

export default { searchApiUrl, close, caretDown, caretUp };
