import createFocusTrap from 'focus-trap';
import { Breakpoints } from '../../scripts/constants';

import Flyout from './navigation-flyout';

const selectors = {
  items: '.cta-nav__items',
  item: '.cta-nav__item',
  mainCTA: '[data-cta="main"]',
};

const dataAttr = {
  ariaExpanded: 'aria-expanded',
  flyoutSelected: 'data-fo-selected',
  isOpen: 'data-is-open',
};

/**
 * NavHorizontal
 * Controls the nav header interactions,
 */
class NavHorizontal {
  /**
   * Sets class props
   * @param {node} element nav
   * @param {function} onOpenState callback to trigger when open state changes
   * @param {node} focusOn element on which the `focus-trap` will be applied
   */
  constructor({ element, onOpenState, focusOn }) {
    if(!element) return;

    this.element = element;
    this.onOpenState = onOpenState;
    this.items = this.element.querySelectorAll(selectors.item);
    this.itemsContent = this.element.querySelector(selectors.items);
    this.mainCTAs = this.itemsContent.querySelectorAll(selectors.mainCTA);

    this.state = {
      open: 'false',
    };

    this.focusTrap = createFocusTrap(focusOn || this.element, {
      onDeactivate: () => {
        if (this.itemsContent.dataset.foSelected) {
          this.setIsOpenOnItems();
          this.toggleAriaExpanded();
          this.itemsContent.setAttribute(dataAttr.flyoutSelected, false);
        }

        this.onOpenState(false);
        window.removeEventListener('resize', this.closeMenuListenerInstance);
      },
    });

    // Workaround for removing the listener within itself when binding `this`
    this.closeMenuListenerInstance = this.closeMenuListener.bind(this);

    // Store current window size, will be used to close de menu on resize
    this.windowInnerWidth = window.innerWidth;

    Array.prototype.slice.call(this.items).forEach(item => new Flyout({ element: item }));
    this.itemsContent.addEventListener('click', this.flyoutSelected.bind(this));

    Array.prototype.slice.call(this.items).forEach(item => {
      item.addEventListener('mouseenter', this.enterflyout.bind(this));
      item.addEventListener('mouseleave', this.leaveflyout.bind(this));
    });
  }

  /**
   * Callback on resize event.
   * Calls toggleMenu() If resize passes the threshold between medium and
   * large viewports
   */
  closeMenuListener({ srcElement }) {
    if (this.windowInnerWidth < Breakpoints.LG && srcElement.innerWidth > Breakpoints.LG) {
      // Resize from medium to large viewport
      if (this.itemsContent.dataset.foSelected) {
        this.focusTrap.deactivate();
        this.setIsOpenOnItems();
        this.toggleAriaExpanded();
        this.itemsContent.setAttribute(dataAttr.flyoutSelected, false);
      }

      this.onOpenState(false);
    } else if (this.windowInnerWidth > Breakpoints.LG && srcElement.innerWidth < Breakpoints.LG) {
      // Resize from large to medium viewport
      if (this.itemsContent.dataset.foSelected) {
        this.focusTrap.deactivate();
        this.setIsOpenOnItems();
        this.toggleAriaExpanded();
        this.itemsContent.setAttribute(dataAttr.flyoutSelected, false);
      }

      this.onOpenState(false);
    }
  }

  /**
   * Update items `data-is-open` property on items
   * @param {bool} isOpen
   */
  setIsOpenOnItems(isOpen = false) {
    Array.prototype.slice
      .call(this.items)
      .forEach(item => item.setAttribute(dataAttr.isOpen, isOpen));
  }

  /**
   * Update items `aria-expanded` property on main CTAs
   * @param {bool} isExpanded
   */
  toggleAriaExpanded(isExpanded = false) {
    Array.prototype.slice
      .call(this.mainCTAs)
      .forEach(cta => cta.setAttribute(dataAttr.ariaExpanded, isExpanded));
  }

  /**
   * Updates component elements in relation to the selected option
   * @param {event} mouseleave
   * @param {event.srcElement} srcElement item container
   */
  leaveflyout() {
    if (this.itemsContent.dataset.foSelected) {
      this.focusTrap.deactivate();
      this.setIsOpenOnItems();
      this.toggleAriaExpanded();

      this.itemsContent.setAttribute(dataAttr.flyoutSelected, false);
    }

    this.onOpenState(false);
    window.removeEventListener('resize', this.closeMenuListenerInstance);
  }

  /**
   * Updates component elements in relation to the selected option
   * @param {event} mouseenter
   * @param {event.srcElement} srcElement item container
   */
  enterflyout({ srcElement }) {
    if (srcElement.dataset.isOpen !== 'true') {
      this.windowInnerWidth = window.innerWidth;
      this.focusTrap.deactivate();

      this.setIsOpenOnItems();
      this.toggleAriaExpanded();

      this.onOpenState(true);

      srcElement.querySelector(selectors.mainCTA).setAttribute(dataAttr.ariaExpanded, true);
      srcElement.setAttribute(dataAttr.isOpen, true);
      srcElement.parentElement.setAttribute(dataAttr.flyoutSelected, true);

      window.addEventListener('resize', this.closeMenuListenerInstance);
    }
  }

  /**
   * Updates component elements in relation to the selected option
   * @param {event} click
   * @param {event.srcElement} srcElement call to action
   */
  flyoutSelected({ srcElement }) {
    if (srcElement.dataset.cta === 'main') {
      if (srcElement.getAttribute('aria-expanded') === 'true') {
        if (this.itemsContent.dataset.foSelected) {
          this.focusTrap.deactivate();
          this.setIsOpenOnItems();
          this.toggleAriaExpanded();

          this.itemsContent.setAttribute(dataAttr.flyoutSelected, false);
        }
      } else {
        this.windowInnerWidth = window.innerWidth;
        this.focusTrap.deactivate(); // clear previous

        this.setIsOpenOnItems();
        this.toggleAriaExpanded();

        srcElement.setAttribute(dataAttr.ariaExpanded, true);
        srcElement.parentElement.setAttribute(dataAttr.isOpen, true);
        srcElement.parentElement.parentElement.setAttribute(dataAttr.flyoutSelected, true);

        this.onOpenState(true);
        window.addEventListener('resize', this.closeMenuListenerInstance);
        this.focusTrap.activate();
      }
    }
  }
}

export default NavHorizontal;
